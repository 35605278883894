import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  OnDestroy,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PropertyService } from "../shared/property.service";
import { OwlOptions } from "ngx-owl-carousel-o";
import { DataShareService } from "../shared/data-share.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Title } from "@angular/platform-browser";
import _ from "lodash";
import { EncrDecrService } from "../shared/EncrDecrService.service";
import { ToastrService } from "ngx-toastr";
import { environment } from "../../../environments/environment";
import { Loader, LoaderOptions } from "google-maps";
import { forEach } from "@angular/router/src/utils/collection";
@Component({
  selector: "app-properties-list",
  templateUrl: "./properties-list.component.html",
  styleUrls: ["./properties-list.component.css"],
})
export class PropertiesListComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  // infinite scroll settings
  public _get = _.get;
  public _uniqBy = _.uniqBy;
  public propertiesList = [];
  public properties = [];
  public currentPage = 1;
  public throttle = 300;
  public scrollDistance = 4;
  public scrollUpDistance = 2;
  public search_obj = {};
  public hasNoPropertyRecords = false;
  public isDisplayMap = false;
  public user = null;
  public imageUrl = environment.imageUrl + "property_image/";

  public lat: number = -33.8781954;
  public long: number = 151.2031137;
  // carousel settings
  public customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    autoplay: false,
    autoWidth: true,
    navSpeed: 700,
    navText: ["&#8249;", "&#8250;"],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 4,
      },
    },
    nav: true,
  };
  @ViewChild("mapView") mapElement: ElementRef;
  public options: LoaderOptions = {
    /* todo */
  };
  public loader = new Loader(
    "AIzaSyB_L4pPNYwWjRXJM_lFvRfKWCNK2L3oNDs",
    this.options
  );
  constructor(
    private route: ActivatedRoute,
    private propertyService: PropertyService,
    private dataShare: DataShareService,
    private spinner: NgxSpinnerService,
    private titleService: Title,
    private EncrDecr: EncrDecrService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.titleService.setTitle(this.route.snapshot.data["title"]);
    window.scrollTo(0, 0);
    this.spinner.show("outer");
    this.propertiesList = [];
    this.properties = this.route.snapshot.data["propertyList"];
    this.search_obj = JSON.parse(localStorage.getItem("search_obj"));
  }

  createPropertyAraay(properties) {
    console.info("--------------------------");
    console.info("CREATE_PROPERTY =>", properties);
    console.info("--------------------------");
    let list = [];
    // properties.forEach((p) => {
    //   if (p.hasOwnProperty("listing")) {
    //     list.push(p.listing);
    //   }
    //   if (p.hasOwnProperty("listings")) {
    //     if (p.listings.length > 0) {
    //       p.listings.forEach((ele) => {
    //         list.push(ele);
    //       });
    //     }
    //   }
    // });
    if (properties && properties.data) {
      for (let index = 0; index < properties.data.length; index++) {
        const propertyItem = properties.data[index];
        // let propertyImg = []
        // propertyItem.image.forEach(item => {
        //   propertyImg.push({...item, path: `${this.imageUrl}${item.path}`})
        // })
        // propertyItem.image = propertyImg
        // console.info('--------------------------')
        // console.info('propertyItem =>',propertyItem)
        // console.info('--------------------------')
        list.push(propertyItem);
      }
    }
    if (list.length === 0) {
      this.hasNoPropertyRecords = true;
    } else {
      this.hasNoPropertyRecords = false;
    }
    this.addItems(list);
    this.spinner.hide("outer");
  }

  addItems(list) {
    const cnt = list.length;
    for (let i = 0; i < cnt; i++) {
      this.propertiesList["push"](list[i]);
    }
    this.propertiesList = this._uniqBy(this.propertiesList, (obj) =>
      [this._get(obj, "_id", "")].join()
    );
    this.spinner.hide("outer");
  }

  onScrollDown() {
    this.currentPage += 1;
    this.search_obj["page"] = this.currentPage;
    // this.dataShare.changeSearchObj(this.search_obj);
    this.search_obj["limit"] = 10;
    this.propertyService
      .getProperties(this.search_obj)
      .toPromise()
      .then(async (res) => {
        this.hasNoPropertyRecords = false;
        await this.createPropertyAraay(res);
      });
  }

  followProperty(propertyId, propertyDetails) {
    let userId = this.user.data._id;
    let search_obj = JSON.parse(localStorage.getItem("search_obj"));
    let findFollow = this.isFollowing(propertyDetails.favDetail)
      ? this.isFollowing(propertyDetails.favDetail)
      : null;
    let status = 1;
    console.info("--------------------------");
    console.info("findFollow =>", findFollow);
    console.info("--------------------------");
    if (findFollow) {
      if (findFollow.fav_status === 1) {
        status = 2;
      } else {
        status = 1;
      }
    } else {
      status = 1;
    }

    this.propertyService
      .addToFavouritesProperty(userId, status, propertyId)
      .toPromise()
      .then(async (res: any) => {
        console.info("--------------------------");
        console.info("FOLLOW_PROPERTY_STATUS =>", res);
        console.info("--------------------------");
        if (res.code == 200) {
          if (status === 1) {
            this.toastr.success(
              "Successfully marked property as favourites",
              "Success!",
              {
                timeOut: 3000,
              }
            );
          } else if (status === 2) {
            this.toastr.success(
              "Successfully unmarked property as favourites",
              "Success!",
              {
                timeOut: 3000,
              }
            );
          }
        }
        this.spinner.show("outer");
        this.dataShare.changeSearchObj(search_obj);
        localStorage.setItem("search_obj", JSON.stringify(search_obj));
      });
  }

  isFollowing(favDetails = []) {
    if (this.user) {
      let findFav = _.filter(
        favDetails,
        (fav) => fav.fav_by === this.user.data._id
      );
      if (findFav.length > 0) {
        return findFav[0];
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  ngOnInit() {
    this.dataShare.isDisplayMap.subscribe((data) => {
      this.isDisplayMap = data;
      console.log("isDisplayMap =>", this.isDisplayMap);
      if (this.isDisplayMap) {
        this.getInitRoadMap();
      }
    });

    this.dataShare.currentsearchObj.subscribe(async (s_obj) => {
      if (s_obj) {
        this.propertiesList = [];
        this.search_obj = s_obj;
        this.currentPage = 1;
        this.search_obj["page"] = this.currentPage;
        this.search_obj["limit"] = 10;
        await this.propertyService
          .getProperties(this.search_obj)
          .toPromise()
          .then(async (res) => {
            this.hasNoPropertyRecords = false;
            await this.createPropertyAraay(res);
          });
      } else {
        this.hasNoPropertyRecords = false;
        this.createPropertyAraay(this.properties);
      }
      this.spinner.hide("outer");
    });
    this.dataShare.loginUser.subscribe((data) => {
      if (data) {
        this.user = localStorage.getItem("user")
          ? this.EncrDecr.getEncrypt(localStorage.getItem("user"))
          : null;
        console.log("this.user :: 1 => ", this.user);
      } else {
        this.user = localStorage.getItem("user")
          ? this.EncrDecr.getEncrypt(localStorage.getItem("user"))
          : null;
        console.log("this.user :: 1 => ", this.user);
      }
    });
    console.info("--------------------------");
    console.info("this.propertiesList =>", this.propertiesList);
    console.info("--------------------------");
  }

  ngAfterViewInit() {}

  getInitRoadMap() {
    const fenway = { lat: -37.840935, lng: 144.946457 };
    console.log("map id===>", document.getElementById("mapView"));
    setTimeout(() => {
      const mapView = new google.maps.Map(document.getElementById("mapView"), {
        center: fenway,
        zoom: 11,
        clickableIcons: true,
        streetViewControl: true,
        streetViewControlOptions: {
          position: google.maps.ControlPosition.LEFT_CENTER,
        },
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_BOTTOM,
        },
        fullscreenControl: true,
        fullscreenControlOptions: {
          position: google.maps.ControlPosition.LEFT_TOP,
        },
        styles: [
          {
            featureType: "all",
            elementType: "all",
            stylers: [{ visibility: "on" }],
          },
        ],
      });
      this.propertiesList.forEach((element) => {
        new google.maps.Marker({
          position: { lat: +element.latitude, lng: +element.longitude },
          map: mapView,
          title: element.address,
          place: element.city,
          clickable: true,
          visible: true,
        });
      }, 1000);
    });
  }

  openPropertyDetailPage(property: any): void {
    console.log(property);
    this.router.navigate([`/property/${property._id}`]);
  }

  ngOnDestroy(): void {
    this.search_obj = {};
  }
}
